.todoListMain .container {
    max-width: 562px;
}

.todoListMain h3 {
    font-family: 'Montserrat', cursive;
    font-size: 2em;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
    color: #074D8C;
}

.todoListMain form {
    margin-top: 20px;
    border-bottom: 1px solid rgba(0,0,0,.5)
}

.todoListMain input {
    padding: 11px;
    font-size: 18px;
    line-height: 1.8;
    width: inherit;
    border: none;
    font-family: 'Montserrat', cursive;
    background-color: #00000000;
    color: #0B67BA;
}

.todoListMain input:focus {
    outline: none;
}

.todoListMain button {
    color: #249F24;
    background-color: #00000000;
    font-size: 36px;
    line-height: 1;
    border: none;
    padding: 0px;
    transition: .25s ease-in-out;
}

.todoListMain button:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.todoListMain button:focus {
    outline: none;
}

.todoListMain .list-group .list-group-item {
    padding: 0px;
    background-color: #00000000;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.5);
    margin-top: 20px;
}

.todoListMain .list-group .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.todoListMain .list-group .list-group-item .row {
    align-items: center;
}

.todoListMain .list-group .deleteButton {
    font-size: 24px;
    color: #C62D2D;
}

.todoListMain div.col-2 {
    text-align: center;
}
